import { takeLatest, put } from 'redux-saga/effects';
import {
  LOAD_SETTLEMENT_OPTIONS,
  setSettlementOptions,
  failedSettlementOptionsLoad
} from './reducer';
import { getRequest } from '../../utilities/requests';

function* handleLoadSettlementOptions() {
  try {
    const url = '/payout_plans';
    const response = yield getRequest(url);
    yield put(setSettlementOptions(response.data.payout_plans));
  } catch (error) {
    console.error(error);
    yield put(failedSettlementOptionsLoad());
  }
}

export function* loadSettlementOptionsSaga() {
  yield takeLatest(LOAD_SETTLEMENT_OPTIONS, handleLoadSettlementOptions);
}
