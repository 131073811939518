// @flow
import React, { Component } from 'react';
import { ButtonInput } from './ButtonStyles';
import { SubmitValidationSmall } from './InputStyles';

type P = {|
  value?: string,
  label?: string,
  disabled: boolean,
  error: ?string,
  marginTop: string
|};

class SubmitButton extends Component<P> {
  static defaultProps = {
    marginTop: '5rem'
  };

  render() {
    return (
      <>
        <ButtonInput
          type="submit"
          {...this.props}
          marginTop={this.props.marginTop}
        />
        {this.props.error &&
          (Array.isArray(this.props.error) ? (
            this.props.error.map((error, index) => (
              <SubmitValidationSmall list key={index}>
                {error}
              </SubmitValidationSmall>
            ))
          ) : (
            <SubmitValidationSmall>{this.props.error}</SubmitValidationSmall>
          ))}
      </>
    );
  }
}

export default SubmitButton;
